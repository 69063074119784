<template>
  <ib-dialog
    :visible="visible"
    fullscreen
    @open="onOpen"
    @close="onClose"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $t('dialogs.setup.ownership.addDialog.addOwners') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper">
      <!-- Header -->
      <add-dialog-header right-end>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $t('dialogs.setup.ownership.addDialog.addOwners') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-5"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.ownership.addDialog.guide.title')"
        :text="$t('pages.businessGuide.ownership.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Content -->
      <div class="mb-2">
        <add-owners-form
          ref="addOwnersForm"
          v-model="owners"
          @percentages="percentages = $event"
          @validate="isValidate = $event"
        />
      </div>

      <!-- Dialog Bottom -->
      <div class="my-4 my-md-0">

        <!-- Divider -->
        <ib-divider block class="my-4" />
        <!-- /Divider -->

        <el-row :gutter="10" class="d-flex">
          <el-col :span="12">
            <ib-button
              class="w-100"
              variant="white"
              size="lg"
              font-family="regular"
              @click="$emit('close')"
            >
              {{ $t('cancel') }}
            </ib-button>
          </el-col>
          <el-col :span="12">
            <ib-button
              class="w-100 text-uppercase"
              size="lg"
              :loading="loading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </ib-button>
          </el-col>
        </el-row>
      </div>
      <!-- /Content -->
    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AddOwnersForm from '@/views/Home/StoryMode/SetUp/Ownership/Components/AddOwnersForm'
import EventBus from '@/event-bus'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'

import { mapActions, mapGetters } from 'vuex'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'

export default {
  name: 'DialogAddOwners',

  components: {
    InfoGuideAddDialog,
    AddDialogHeader,
    AddOwnersForm
  },

  mixins: [
    MixinDialog,
    MixinGuide
  ],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      owners: [],
      guideVisible: false,
      percentages: 0,
      isValidate: false
    }
  },

  computed: {
    ...mapGetters('idea', ['getIdeaId']),
    ...mapGetters('idea/ownership', ['ownership', 'getOwners']),

    isSaveDisabled () {
      return this.percentages !== 100 || !this.isValidate
    }
  },

  methods: {
    ...mapActions('idea/ownership', ['updateOwners']),

    onOpen () {
      this.openGuideDialog('addOwnershipGuide')
      this.setData()
    },

    onClose () {
      this.$emit('close')
      this.$notify.closeAll()
      this.$refs.addOwnersForm.nextColorIndex = this.getOwners.length
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    async save () {
      try {
        this.loading = true
        const payload = this.formatPayload(this.owners)
        await this.updateOwners({ ideaId: this.getIdeaId, payload })

        this.setData()
        this.loading = false
        EventBus.$emit('owners-changed')
        this.onClose()
      } catch (error) {
        this.loading = false
      }
    },

    setData () {
      this.owners = this.formatOwners()
    },

    formatOwners () {
      return this.getOwners.map(owner => {
        return {
          id: owner.id,
          ownership_id: this.ownership.id,
          color: owner.color,
          name: owner.name,
          percentage: owner.percentage,
          focusName: false,
          focusPercentage: false
        }
      })
    },

    formatPayload (payload) {
      return payload.map(owner => {
        return {
          id: owner.id,
          ownership_id: owner.ownership_id,
          color: owner.color,
          name: owner.name,
          percentage: owner.percentage
        }
      })
    }
  }
}
</script>
